import Accordion from 'components/Accordion';
import Selection from 'components/Forms/Selection';
import TextField from 'components/Forms/TextField';
import TextFieldBase from 'components/Forms/TextFieldBase';
import LoadingIndicator from 'components/LoadingIndicator';
import { CHF, INTEREST_RATE_STEP, leasingProviderOptions, MAX_PRICE, MIN_PRICE } from 'constants/constants';
import { useFormikContext } from 'formik';
import useIsLockedToSource from 'pages/AddEditListings/hooks/useIsLockedToSource';
import { RootState } from 'pages/_store/root-reducer';
import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConditionType, DefaultVendorParams, LeasingProvider, ResidualValueCalculation } from 'types';
import { isNil } from 'utils/checks';
import { compose } from 'utils/compose';
import { parseInterestRateTo100 } from 'utils/formatting';
import { AddEditListingSchema } from '../../schema';
import { Actions as AddEditListingsActions } from '../../store/actions';
import { getMakeFromSlug } from '../../utils';
import styles from './CarPriceForm.module.scss';
import Translations from './CarPriceForm.translations';
import AutoI from './components/AutoI';
import ResidualValueBreakdownForm from './components/ResidualValueBreakdownForm';

interface DealOwnershipForm {
  leasingProvider?: LeasingProvider;
  interestRate?: number;
  leaseTypePrivate?: boolean;
}

interface Props {
  isCreate?: boolean;
}

const CarPriceForm: FC<Props> = ({ isCreate }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<AddEditListingSchema>();
  const { deal, defaultDealOwnership, isLoadingResidualValues, isLoadingAction, isLoadingDeal } = useSelector(
    (state: RootState) => state.addEditListingsPage,
    shallowEqual
  );
  const { vendorId } = useParams<DefaultVendorParams>();
  const dispatch = useDispatch();

  const setDealOwnershipFormValues = useCallback(
    (dealOwnershipForm: DealOwnershipForm) => {
      setFieldValue('interestRate', dealOwnershipForm.interestRate);
      setFieldValue('leasingProvider', dealOwnershipForm.leasingProvider);
      setFieldValue('leaseTypePrivate', dealOwnershipForm.leaseTypePrivate);
    },
    [setFieldValue]
  );

  const loadDefaultLeasingTerms = () => {
    dispatch(
      AddEditListingsActions.getDealOwnershipRequest({
        vendorId: Number(vendorId),
        make: getMakeFromSlug(values.make),
        vehicleType: values.condition as ConditionType,
      })
    );
  };

  useEffect(() => {
    if (isCreate) loadDefaultLeasingTerms();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { isLockedToSource, lockedToSourceMessage } = useIsLockedToSource();

  useEffect(() => {
    if (defaultDealOwnership) {
      setDealOwnershipFormValues({
        leasingProvider: defaultDealOwnership.leasingProvider,
        leaseTypePrivate: !defaultDealOwnership.businessLeasing,
        interestRate: parseInterestRateTo100(defaultDealOwnership.leasingInterestRate),
      });
    } else if (deal && !isNil(deal.leasingInterestRate)) {
      setDealOwnershipFormValues({
        leasingProvider: deal.leasingProvider,
        leaseTypePrivate: !deal.businessLeasing,
        interestRate: parseInterestRateTo100(deal.leasingInterestRate),
      });
    }
  }, [deal, defaultDealOwnership, setDealOwnershipFormValues]);

  useEffect(() => {
    if (!deal || !deal.residualValueCalculation) return;
    setFieldValue('isNewPriceRequired', deal.residualValueCalculation === ResidualValueCalculation.ON_LIST_PRICE);
  }, [deal, setFieldValue]);

  const isLoading = isLoadingResidualValues || isLoadingAction || isLoadingDeal;

  const setUnsavedChanges = () => dispatch(AddEditListingsActions.setUnsavedChanges());

  return (
    <div className={styles.root}>
      <LoadingIndicator isLoading={isLoading}>
        <Accordion header={formatMessage(Translations.CarPriceFormPricing)} collapsable={false}>
          <div className={styles.headerLabel}>{formatMessage(Translations.CarPriceFormPriceTitle)}</div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="price"
                type="number"
                label={formatMessage(Translations.CarPriceFormPrice)}
                startAdornment={CHF}
                required
                min={MIN_PRICE}
                max={MAX_PRICE}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="newPrice"
                type="number"
                label={formatMessage(Translations.CarPriceFormNewPrice)}
                startAdornment={CHF}
                min={MIN_PRICE}
                max={MAX_PRICE}
                required={deal?.residualValueCalculation === ResidualValueCalculation.ON_LIST_PRICE}
                onChange={setUnsavedChanges}
                disabled={isLockedToSource}
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <TextField
                name="dealerDeliveryFee"
                type="number"
                label={formatMessage(Translations.CarPriceFormDealerDeliveryFee)}
                startAdornment={CHF}
                required
                min={0}
                max={MAX_PRICE}
                onChange={setUnsavedChanges}
                disabled
                tooltip={lockedToSourceMessage}
              />
            </div>
            <div className={styles.formRow}>
              <TextFieldBase
                key={deal?.totalCarPrice}
                name="totalCarPrice"
                type="number"
                label={formatMessage(Translations.CarPriceFormTotalCarPrice)}
                startAdornment={CHF}
                min={MIN_PRICE}
                max={MAX_PRICE}
                required
                value={deal?.totalCarPrice}
                disabled
                readOnly
              />
            </div>
            <div className={styles.formRow} />
          </div>
          <div className={styles.divider} />
          <div className={styles.headerLabel}>{formatMessage(Translations.CarPriceFormLeasingTerms)}</div>
          <div className={styles.formColumn}>
            <div className={styles.formRow}>
              <Selection
                name="leasingProvider"
                label={formatMessage(Translations.CarPriceFormLeasingProvider)}
                options={leasingProviderOptions}
                required
                onChange={setUnsavedChanges}
                disabled
              />
            </div>
            <div className={styles.formRow}>
              <TextField
                name="interestRate"
                type="number"
                min={0}
                max={100}
                step={INTEREST_RATE_STEP}
                label={formatMessage(Translations.CarPriceFormInterestRate)}
                startAdornment={'%'}
                required
                onChange={setUnsavedChanges}
                disabled
              />
            </div>
            <div className={styles.formRow} />
          </div>
          {!isCreate && (
            <>
              <div className={styles.divider} />
              <AutoI />
              <div className={styles.divider} />
              <ResidualValueBreakdownForm />
            </>
          )}
        </Accordion>
      </LoadingIndicator>
    </div>
  );
};

export default compose<Props>(CarPriceForm);
