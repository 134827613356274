import { LeasingProvider, LocaleConstant, SelectOption, TermPeriod } from 'types';

export type MonthType = 12 | 24 | 36 | 48 | 60;
export type KMType = 10000 | 15000 | 20000 | 25000 | 30000 | 35000 | 40000 | 50000;
export const MAX_KM_AT_END_OF_LEASE = 200000;
export const MINIMAL_RESIDUAL_VALUE = 1000;
export const MAX_AGE_OF_CAR_IN_YEARS_AT_END_OF_LEASE = 10;
export const DEFAULT_LEASING_PERIOD = 48;
export const DEFAULT_LEASING_MILEAGE = 10000;
export const DEFAULT_LEASING_DOWNPAYMENT_TYPE = 'r';
export const DEFAULT_LEASING_DOWNPAYMENT = 0.15;
export const DEFAULT_LEASING_DOWNPAYMENT_IN_CURRENCY = 3000;
export const DEFAULT_LEASING_DOWNPAYMENT_IN_PERCENT = 0.15;
export const DEFAULT_DEALER_DELIVERY_FEE_IN_CHF = 500;
export const MIN_PRICE = 6000;
export const MAX_PRICE = 10000000;

export const MAX_LOAN_AMOUNT = 100000;
export const MIN_LOAN_AMOUNT = 1000;

export const VALID_MONTHS: MonthType[] = [12, 24, 36, 48, 60];
export const LEASING_PERIOD_ON_PLATFORM = VALID_MONTHS;
export const VALID_KM: KMType[] = [10000, 15000, 20000, 25000, 30000, 35000, 40000, 50000];
export const VALID_TERM_PERIODS: TermPeriod[] = [6, 12, 24, 36, 48, 60, 72, 84];

export const LEASING_MILEAGE_ON_PLATFORM = VALID_KM;
export const MINIMUM_LEASING_PERIOD: MonthType = Math.min(...LEASING_PERIOD_ON_PLATFORM) as MonthType;
export const MAXIMUM_LEASING_PERIOD: MonthType = Math.max(...LEASING_PERIOD_ON_PLATFORM) as MonthType;
export const MINIMUM_LEASING_KM: KMType = Math.min(...LEASING_MILEAGE_ON_PLATFORM) as KMType;
export const MAXIMUM_LEASING_KM: KMType = Math.max(...LEASING_MILEAGE_ON_PLATFORM) as KMType;

export const INTEREST_RATE_STEP = 0.01;

export const LocaleOptions: LocaleConstant = {
  'de-ch': {
    label: 'Deutsch',
    shortLabel: 'DE',
    value: 'de-ch',
  },
  en: {
    label: 'English',
    shortLabel: 'EN',
    value: 'en',
  },
  'fr-ch': {
    label: 'Français',
    shortLabel: 'FR',
    value: 'fr-ch',
  },
};

export enum SupportedLanguages {
  EN = 'en',
  FR = 'fr',
  DE = 'de',
}

const CURRENT_YEAR = new Date().getFullYear();

export const SUPPORT_EMAIL_LINK = 'sales-support@gowago.ch';

export const GOWAGO_FOOTER_COPYRIGHT = `© ${CURRENT_YEAR} - GOWAGO AG`;

export const GOWAGO_OPEN_HOURS = '9:00 - 18:00';

export const GOWAGO_CONTACT_PHONE = '+41 44 461 13 44';

export const CHF = 'CHF';

export const DATE_FORMAT = 'MM/DD/YY';
export const FULL_YEAR_MONTH_DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_PICKER_FORMAT = 'YYYY-MM-DD';
export const DATE_PICKER_FORMAT_VIEW = 'DD/MM/YYYY';

export const DATE_TIME_FORMAT = 'hh:mm, D MMM YYYY';

export const leasingProviderOptions: SelectOption[] = [
  {
    label: 'Migros Bank',
    value: LeasingProvider.MIGROS_BANK,
  },
];

export const MAX_KM = MAX_KM_AT_END_OF_LEASE - MINIMUM_LEASING_KM;
export const MAX_AGE = MAX_AGE_OF_CAR_IN_YEARS_AT_END_OF_LEASE * 12 - MINIMUM_LEASING_PERIOD;

export const STANDARD_EQUIPMENT_FORM_TRANSLATIONS_KEY = 'StandardEquipmentForm';

export const DEFAULT_DEBOUNCE_TIMEOUT = 500;
