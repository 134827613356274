import Card from 'components/Card';
import EmptyUserAvatar from 'components/EmptyUserAvatar';
import ButtonBase from 'components/Forms/ButtonBase';
import TextField from 'components/Forms/TextField';
import { routeConstants } from 'constants/routes';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Actions as GlobalActions } from 'pages/_store/global/actions';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import GoogleLoginButton from '../../../components/Forms/ButtonGoogleAuth/ButtonGoogleAuth';
import { RootState } from '../../_store/root-reducer';
import ContactSupportLink from '../SharedComponents/ContactSupportLink';
import PublicHeader from '../SharedComponents/PublicHeader';
import styles from './Login.module.scss';
import messages from './LoginPage.translations';

interface LoginFormValue {
  email: string;
  password: string;
}

const Login: FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isLoginFetching, isLoginInvalid } = useSelector((state: RootState) => state.globalAppState, shallowEqual);
  const LoginFormSchema = Yup.object().shape({
    email: Yup.string().email(formatMessage(messages.LoginPageInvalidEmailFormat)),
    password: Yup.string().required(formatMessage(messages.LoginPageThisFieldIsRequired)),
  });

  const handleLogin = async (values: LoginFormValue, actions: FormikHelpers<LoginFormValue>) => {
    await dispatch(GlobalActions.loginRequest(values));
    actions.setSubmitting(false);
  };

  return (
    <div className={styles.root}>
      <PublicHeader
        title={formatMessage(messages.LoginPageWelcomeBack)}
        subTitle={formatMessage(messages.LoginPageSignInToYourAccountToContinue)}
      />
      <Card>
        <div className={styles.formContainer}>
          <div className={styles.formAvatarContainer}>
            <EmptyUserAvatar />
          </div>
          {isLoginInvalid && (
            <div className={styles.errorCard}>
              {formatMessage(messages.LoginPageInvalidLoginMessage, {
                recoverlink: (msg) => (
                  <Link className={styles.recoverPasswordLink} to={'/reset-password'}>
                    {msg}
                  </Link>
                ),
              })}
            </div>
          )}
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginFormSchema}
            onSubmit={handleLogin}
            render={(props: FormikProps<LoginFormValue>) => (
              <form onSubmit={props.handleSubmit}>
                <TextField
                  name="email"
                  placeholder={formatMessage(messages.LoginPageEnterYourEmail)}
                  type="email"
                  label="Email"
                />
                <TextField
                  name="password"
                  placeholder={formatMessage(messages.LoginPageEnterYourPassword)}
                  type="password"
                  label={formatMessage(messages.LoginPagePassword)}
                />

                <Link to={routeConstants.PUBLIC.RESET_PASSWORD_PAGE.route} className={styles.forgotPasswordLink}>
                  {formatMessage(messages.LoginPageForgotPasswordHelperText)}
                </Link>

                <div className={styles.submitButtonContainer}>
                  <ButtonBase
                    type="submit"
                    classes={{ root: styles.submitButton }}
                    disabled={isLoginFetching}
                    isLoading={isLoginFetching}
                  >
                    {formatMessage(messages.LoginPageSignIn)}
                  </ButtonBase>
                </div>
              </form>
            )}
          />
        </div>
      </Card>
      <ContactSupportLink label={formatMessage(messages.LoginPageDontHaveAnAccountYet)} />
      <div className={styles.googleLoginButtonCard}>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default Login;
