import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styles from './EmptyUserAvatar.module.scss';

const EmptyUserAvatar: FC = () => (
  <div className={styles.root}>
    <FontAwesomeIcon icon={['fas', 'user']} className={styles.icon} />
  </div>
);

export default EmptyUserAvatar;
